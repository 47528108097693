import { Output, Input } from '@angular/core';
import { FireStoreService } from '../../../../services/fire-store.service';
import { TranslateService } from '@ngx-translate/core';
import { ChartConfiguration, ChartType, TooltipItem } from 'chart.js';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
} from '@angular/core';

import { formatDateLabel } from '../../../../../util';
import { DatareportRepository } from 'src/app/dashboard/repositories/datareport.repository';
import { DateUtil } from 'src/app/shared/utils/date-util';

@Component({
  selector: 'app-chart-timeflag',
  templateUrl: './chart-timeflag.component.html',
  styleUrls: [
    '../chart.component.css',
    './chart-timeflag.component.css',
    '../../../../../dashboard/common.css',
  ],
})
export class ChartTimeflagComponent implements OnInit {
  @Input() mapID!: string;
  @Input() fromDate!: string;
  @Input() toDate!: string;

  @Output() dateChangeEvent = new EventEmitter();
  chartTitle = '利用時間帯別の人数';
  chartType: ChartType = 'line';
  chartOptions: ChartConfiguration['options'] = {
    animation: {
      delay: 0,
    },
    elements: {
      point: {
        radius: 1,
        borderWidth: 1,
        hitRadius: 8,
      },
      line: {
        tension: 0,
      },
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          autoSkip: true,
          autoSkipPadding: 10,
          color: '#989eb3',
        },
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        ticks: {
          maxTicksLimit: 4,
          color: '#989eb3',
          callback: (tickValue: string | number): string => {
            return tickValue.toString();
          },
        },
        grid: {
          color: '#d8dde5',
          drawBorder: false,
        },
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'index',
        position: 'average',
        backgroundColor: '#6666cc',
        borderColor: '#fff',
        titleFont: {
          style: 'normal',
        },
        bodySpacing: 6,
        callbacks: {
          label: (context: TooltipItem<'line'>): string => {
            const label = context.dataset.label ?? '';
            const yLabel = context.parsed.y ?? '';
            return `${label}: ${yLabel.toLocaleString()}`;
          },
        },
      },
    },
    responsive: true,
  };
  chartLabels = [''];
  chartData = [
    {
      data: [0],
      label: '',
    },
    {
      data: [0],
      label: '',
    },
    {
      data: [0],
      label: '',
    },
    {
      data: [0],
      label: '',
    },
  ];

  allData: Record<string, number[]> = {};
  dateLabel: string[] = [];
  graphData: Record<string, unknown> = {};
  timezone: string[] = [];
  irregularData = false;

  get isEmptyAllData(): boolean {
    return Object.keys(this.allData).length === 0;
  }

  get active(): { [key: string]: string } {
    return {
      visibility:
        !this.isEmptyAllData && !this.irregularData ? 'visible' : 'hidden',
    };
  }

  constructor(
    private readonly fsService: FireStoreService,
    private readonly datareportRepository: DatareportRepository,
    private readonly cd: ChangeDetectorRef,
    private readonly translate: TranslateService,
  ) {
    this.translate
      .get([
        'user.timezone1',
        'user.timezone2',
        'user.timezone3',
        'user.timezone4',
      ])
      .subscribe((translations) => {
        this.timezone[0] = translations['user.timezone1'];
        this.timezone[1] = translations['user.timezone2'];
        this.timezone[2] = translations['user.timezone3'];
        this.timezone[3] = translations['user.timezone4'];
      });
  }

  ngOnInit(): void {}

  isNumberStr(str: string): boolean {
    return Number.isFinite(Number(str));
  }

  // Ver.1 用
  async getAndDrawData(
    mapID: string,
    fromDate: string,
    toDate: string,
    timezone: string,
  ): Promise<void> {
    this.dateLabel = DateUtil.dateRangeWithFormat(fromDate, toDate);
    const dtos = await this.datareportRepository.fetchTimeflag(
      mapID,
      fromDate,
      toDate,
    );
    // 時間帯別のPV数
    const data = this.dateLabel.reduce(
      (acc: Record<string, number[]>, date) => {
        const dto = dtos.find((dto) => dto.date === date);
        if (dto) {
          const pvs = dto.allarea.pv.all;
          acc[dto.date] = [
            (pvs[1] ?? 0) + (pvs[2] ?? 0), // 0時〜6時
            (pvs[3] ?? 0) + (pvs[4] ?? 0), // 6時〜12時
            (pvs[5] ?? 0) + (pvs[6] ?? 0), // 12時〜18時
            (pvs[7] ?? 0) + (pvs[8] ?? 0), // 18時〜24時
          ];
        } else {
          acc[date] = [0, 0, 0, 0];
        }
        return acc;
      },
      {},
    );

    this.allData = data;
    this.drawData();
  }

  drawData(): void {
    if (Object.values(this.allData).length !== 0) {
      const dataWithDateLabel = this.fsService.makeDataWithDateLabelForTimeflag(
        this.dateLabel,
        this.allData,
      );
      this.setDataOpt(dataWithDateLabel);

      this.chartLabels = formatDateLabel(this.dateLabel);
      this.cd.markForCheck(); // marks path
    }
  }

  setDataOpt(data: number[][]): void {
    for (let idx = 0; idx < 8; idx++) {
      if (data[idx] == undefined) {
        break;
      } // 実際に取得しているデータが4個のため、undefinedが来たら処理を抜ける
      if (data[idx].filter((value) => value == undefined).length > 0) {
        this.irregularData = true;
        return;
      }

      const dataOpt = Object.create(dataOptOrig);
      dataOpt.data = data[idx];
      dataOpt.label = this.timezone[idx];
      dataOpt.fill = true;
      dataOpt.borderWidth = 4;
      (dataOpt.pointBorderWidth = 2),
        (dataOpt.pointBorderColor = borderColor[idx]);
      dataOpt.pointBackgroundColor = borderColor[idx];
      dataOpt.borderColor = borderColor[idx];
      dataOpt.backgroundColor = backgroundColor[idx];
      dataOpt.pointHoverBorderColor = backgroundColor[idx];
      dataOpt.pointHoverBackgroundColor = backgroundColor[idx];
      this.chartData[idx] = dataOpt;
    }

    this.irregularData = false;
  }
}

const timeFlagObjOrig = {
  1: 0,
  2: 0,
  3: 0,
  4: 0,
  5: 0,
  6: 0,
  7: 0,
  8: 0,
};

const dataOptOrig = {
  data: [0],
  label: '',
  borderWidth: 4,
};
const borderColor = ['#cea716', '#417b79', '#6b96cb', '#b4addf'];
const backgroundColor = [
  'rgba(206, 167, 22, 0.2)',
  'rgba(65, 123, 121, 0.2)',
  'rgba(107, 150, 203, 0.2)',
  'rgba(180, 173, 223, 0.2)',
];

import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartConfiguration, ChartOptions, ChartType } from 'chart.js';
import { Observable } from 'rxjs';
import { FireStoreService } from 'src/app/dashboard/services/fire-store.service';
import {
  adjustDataForChart,
  getDonutsChartBackgroundColor,
  getDonutsChartHoverBackgroundColor,
} from '../chart-options';

@Component({
  selector: 'app-chart-lang',
  templateUrl: './chart-lang.component.html',
  styleUrls: ['../chart.component.css', './chart-lang.component.css'],
})
export class ChartLangComponent implements OnInit {
  @Input() mapID!: string;
  chartTitle = '言語別ユーザー割合';

  public pieChartOptions: ChartOptions = {
    responsive: true,
    // maintainAspectRatio: false,
    aspectRatio: 1.75,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          boxWidth: 12,
          color: '#333',
        },
      },
      tooltip: {
        mode: 'nearest',
        backgroundColor: '#6666cc',
        borderColor: '#fff',
        borderWidth: 1,
        titleFont: {
          style: 'normal',
        },
        bodySpacing: 6,
      },
    },
  };
  public pieChartData: ChartConfiguration['data'] = {
    labels: ['none'],
    datasets: [
      {
        borderWidth: 0,
        data: [1],
        backgroundColor(params: { dataIndex: number }): string {
          return getDonutsChartBackgroundColor(params.dataIndex);
        },
        hoverBackgroundColor(params: { dataIndex: number }): string {
          return getDonutsChartHoverBackgroundColor(params.dataIndex);
        },
      },
    ],
  };

  public getDonutsChartBackgroundColor = getDonutsChartBackgroundColor;
  public pieChartType: ChartType = 'doughnut';
  // TODO: `!` を使わないように検討してください.
  aggData!: Record<string, number>;
  tableData: Record<string, Record<string, number>> = {};

  tableDataLabels: string[] = [];
  irregularData = false;
  unknown = '';

  get visibility(): { [key: string]: string } {
    return {
      visibility: this.irregularData ? 'hidden' : 'visible',
    };
  }

  constructor(
    private fsService: FireStoreService,
    private cd: ChangeDetectorRef,
    private translate: TranslateService,
  ) {
    this.translate.get(['Unknown']).subscribe((translation) => {
      this.unknown = translation['Unknown'];
    });
  }

  ngOnInit(): void {}

  // Ver.1
  async getAndDrawData(
    mapID: string,
    fromDate: string,
    toDate: string,
    timezone: string,
  ): Promise<void> {
    const { allarea } = await this.fsService.getV1Data(
      'v1lang',
      mapID,
      fromDate,
      toDate,
      timezone,
    );
    this.tableData = allarea;
    this.aggData = allarea.pv;
    this.checkData();
    this.drawData();
  }

  drawData(): void {
    const sortedData = this.sortByValue(this.aggData);
    if (sortedData.keys.length > 20) {
      const chartData = adjustDataForChart(
        sortedData.keys,
        sortedData.values,
        20,
      );
      this.pieChartData.datasets[0].data = chartData.values;
      this.pieChartData.labels = chartData.keys;
      this.tableDataLabels = sortedData.keys;
    } else {
      this.pieChartData.datasets[0].data = sortedData.values;
      this.pieChartData.labels = sortedData.keys;
      this.tableDataLabels = sortedData.keys;
    }
    // 変更検知を行うために再代入している
    this.pieChartData = { ...this.pieChartData };
  }

  checkData(): void {
    if (
      Object.entries(this.aggData).filter((value) => value == undefined)
        .length > 0
    ) {
      this.irregularData = true;
    } else {
      this.irregularData = false;
    }
  }

  sortByValue(data: Record<string, number>): {
    keys: string[];
    values: number[];
  } {
    const sortedMap = new Map(
      Object.entries(data).sort(function (a, b) {
        // ソート処理
        const genreA = a[1];
        const genreB = b[1];

        let comparison = 0;
        if (genreA > genreB) {
          comparison = 1;
        } else if (genreA < genreB) {
          comparison = -1;
        }
        return comparison * -1;
      }),
    );

    const sortedKey: string[] = [];
    const sortedValue: number[] = [];
    sortedMap.forEach((val, key) => {
      sortedKey.push(key == '' ? this.unknown : key);
      sortedValue.push(val);
    });

    return {
      keys: sortedKey,
      values: sortedValue,
    };
  }
}

import { FireStoreService } from '../../../../services/fire-store.service';
import { TranslateService } from '@ngx-translate/core';
import { ChangeDetectorRef, Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import dayjs from 'dayjs';

import { formatDateLabel } from '../../../../../util';
import { ChartConfiguration, ChartDataset, ChartType } from 'chart.js';

@Component({
  selector: 'app-chart-uu',
  templateUrl: './chart-uu.component.html',
  styleUrls: [
    '../chart.component.css',
    './chart-uu.component.css',
    '../../../../../dashboard/common.css',
  ],
})
export class ChartUuComponent implements OnInit {
  @Input() mode!: 'weekly' | 'detailed';
  chartType: ChartType = 'bar';
  chartOptions: ChartConfiguration['options'] = {
    animation: {
      duration: 0,
    },
    elements: {
      point: {
        radius: 4,
      },
    },
    scales: {
      x: {
        ticks: {
          autoSkip: true,
          autoSkipPadding: 10,
          color: '#989eb3',
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          color: '#989eb3',
          maxTicksLimit: 4,
        },
        grid: {
          color: '#d8dde5',
          drawBorder: false,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'index',
        position: 'average',
        backgroundColor: '#6666cc',
        borderColor: '#fff',
        titleFont: {
          style: 'normal',
        },
        bodySpacing: 6,
      },
    },
  };
  chartLabels = [''];

  chartData = [
    {
      data: [0],
      label: 'page views',
      type: 'line',
      fill: false,
      borderWidth: 4,
      pointBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 4,
      borderColor: '#efe08f',
      backgroundColor: '#efe08f',
      pointHoverBorderColor: '#efe08f',
      pointHoverBackgroundColor: '#efe08f',
      pointBorderColor: '#efe08f',
      pointBackgroundColor: '#efe08f',
    },
    {
      data: [0],
      label: 'iframe views',
      type: 'line',
      fill: false,
      borderWidth: 4,
      pointBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 4,
      borderColor: '#8fb8ef',
      pointHoverBorderColor: '#8fb8ef',
      pointHoverBackgroundColor: '#8fb8ef',
      pointBorderColor: '#8fb8ef',
      pointBackgroundColor: '#8fb8ef',
    },
    {
      data: [0],
      label: 'page users',
      borderWidth: 1,
      borderColor: '#ffffff',
      backgroundColor: '#b4addf',
      hoverBackgroundColor: '#7F7BAF',
      hoverBorderColor: '#fff',
      barPercentage: 1,
      barThickness: 'flex',
    },
    {
      data: [0],
      label: 'iframe users',
      borderWidth: 1,
      borderColor: '#ffffff',
      backgroundColor: '#cbcad1',
      hoverBackgroundColor: '#A6A5AC',
      hoverBorderColor: '#fff',
      barPercentage: 1,
      barThickness: 'flex',
    },
  ];
  datasets = this.chartData as ChartDataset<ChartType>[];

  graphData: Record<string, Record<string, number>> = {};
  graphDataInmap: Record<string, Record<string, number>> = {};
  dateLabel: string[] = [];
  irregularData = false;

  get visibility(): { [key: string]: string } {
    return {
      visibility: this.irregularData ? 'hidden' : 'visible',
    };
  }

  constructor(
    private fsService: FireStoreService,
    private cd: ChangeDetectorRef,
    private translate: TranslateService,
  ) {
    this.translate
      .get([
        'weekly.PageViews',
        'weekly.iframeViews',
        'weekly.PageUsers',
        'weekly.iframeUsers',
      ])
      .subscribe((translations) => {
        this.chartData[0].label = translations['weekly.PageViews'];
        this.chartData[1].label = translations['weekly.iframeViews'];
        this.chartData[2].label = translations['weekly.PageUsers'];
        this.chartData[3].label = translations['weekly.iframeUsers'];
      });
  }

  ngOnInit(): void {
    if (this.mode == 'weekly') {
      this.chartData[2].barThickness = '20';
      this.chartData[3].barThickness = '20';
    }
  }

  isNumberStr(str: string): boolean {
    return Number.isFinite(Number(str));
  }

  getDateRangeNum(fromDate: string, toDate: string): number {
    return dayjs(toDate).diff(fromDate, 'day') + 1;
  }

  getDateRange(fromDateStr: string, toDateStr: string): string[] {
    const fromDate = dayjs(fromDateStr);
    const toDate = dayjs(toDateStr);
    const days = dayjs(toDate).diff(fromDate, 'day') + 1;
    const dateRange = [...Array(days)].map((_, i: number) =>
      fromDate.add(i, 'day').format('YYYY-MM-DD'),
    );
    return dateRange;
  }

  // Ver.1 用
  async getAndDrawData(
    mapID: string,
    fromDate: string,
    toDate: string,
    timezone: string,
  ) {
    const { allarea, inmap } = await this.fsService.getV1Data(
      'v1miaccess',
      mapID,
      fromDate,
      toDate,
      timezone,
      true,
    );
    this.graphData = allarea;
    this.graphDataInmap = inmap;
    this.dateLabel = Object.keys(allarea.pv).sort();
    this.checkData();
    this.drawData();
    this.cd.markForCheck(); // marks path
  }

  drawData(): void {
    const dataWithDateLabel = this.fsService.makeDataWithDateLabel(
      this.dateLabel,
      this.graphData['pv'],
    );
    const dataWithDateLabelInmap = this.fsService.makeDataWithDateLabel(
      this.dateLabel,
      this.graphData['pv_iframe'],
    );
    const dataWithDateLabelPageUsers = this.fsService.makeDataWithDateLabel(
      this.dateLabel,
      this.graphData['uu'],
    );
    const dataWithDateLabelIframeUsers = this.fsService.makeDataWithDateLabel(
      this.dateLabel,
      this.graphData['uu_iframe'],
    );
    this.chartData[0].data = dataWithDateLabel;
    this.chartData[1].data = dataWithDateLabelInmap;
    this.chartData[2].data = dataWithDateLabelPageUsers;
    this.chartData[3].data = dataWithDateLabelIframeUsers;
    this.chartLabels = formatDateLabel(this.dateLabel);

    this.cd.markForCheck(); // marks path
  }

  checkData(): void {
    if (
      Object.entries(this.graphData['pv']).filter((value) => value == undefined)
        .length > 0 ||
      Object.entries(this.graphData['pv_iframe']).filter(
        (value) => value == undefined,
      ).length > 0 ||
      Object.entries(this.graphData['uu']).filter((value) => value == undefined)
        .length > 0 ||
      Object.entries(this.graphData['uu_iframe']).filter(
        (value) => value == undefined,
      ).length > 0
    ) {
      this.irregularData = true;
    } else {
      this.irregularData = false;
    }
  }
}

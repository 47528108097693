import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartType, ChartOptions, ChartConfiguration } from 'chart.js';
import { FireStoreService } from 'src/app/dashboard/services/fire-store.service';
import {
  adjustDataForChart,
  getDonutsChartBackgroundColor,
  getDonutsChartHoverBackgroundColor,
} from '../chart-options';

@Component({
  selector: 'app-chart-device',
  templateUrl: './chart-device.component.html',
  styleUrls: ['../chart.component.css', './chart-device.component.css'],
})
export class ChartDeviceComponent implements OnInit {
  chartTitle = 'デバイス割合';

  // Pie
  public pieChartOptions: ChartOptions<ChartType> = {
    responsive: true,
    aspectRatio: 1.75,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: 'bottom',
        fullSize: false,
        labels: {
          boxWidth: 12,
          color: '#333',
        },
      },
      tooltip: {
        mode: 'nearest',
        backgroundColor: '#6666cc',
        borderColor: '#fff',
        borderWidth: 1,
        titleFont: {
          style: 'normal',
        },
        bodySpacing: 6,
      },
    },
  };
  public pieChartData: ChartConfiguration['data'] = {
    labels: ['Mobile', 'PC'],
    datasets: [
      {
        borderWidth: 0,
        data: [50, 50],
        backgroundColor(params: { dataIndex: number }): string {
          return getDonutsChartBackgroundColor(params.dataIndex);
        },
        hoverBackgroundColor(params: { dataIndex: number }): string {
          return getDonutsChartHoverBackgroundColor(params.dataIndex);
        },
      },
    ],
  };
  public getDonutsChartBackgroundColor = getDonutsChartBackgroundColor;
  public pieChartType: ChartType = 'doughnut';
  aggData!: Record<string, number>;
  tableData: Record<string, Record<string, number>> = {};
  tableDataLabels: string[] = [];
  irregularData = false;
  unknown = '';

  get visibility(): { [key: string]: string } {
    return {
      visibility: this.irregularData ? 'hidden' : 'visible',
    };
  }

  constructor(
    private fsService: FireStoreService,
    private cd: ChangeDetectorRef,
    private translate: TranslateService,
  ) {
    this.translate.get(['Unknown']).subscribe((translation) => {
      this.unknown = translation['Unknown'];
    });
  }

  ngOnInit(): void {}

  // Ver.1 用
  async getAndDrawData(
    mapID: string,
    fromDate: string,
    toDate: string,
    timezone: string,
  ) {
    const { allarea } = await this.fsService.getV1Data(
      'v1model',
      mapID,
      fromDate,
      toDate,
      timezone,
    );
    // Chrome: User-Agent Reduction 対策
    // "K" -> "Android"
    // https://www.chromium.org/updates/ua-reduction/
    for (const type in allarea) {
      if ('K' in allarea[type]) {
        allarea[type]['Android'] = allarea[type]['Android'] || 0;
        allarea[type]['Android'] += allarea[type]['K'];
        delete allarea[type]['K'];
      }
    }
    this.tableData = allarea;
    this.aggData = allarea.pv;
    this.checkData();
    this.drawData();
  }

  drawData(): void {
    const sortedData = this.sortByValue(this.aggData);
    if (sortedData.keys.length > 20) {
      const chartData = adjustDataForChart(
        sortedData.keys,
        sortedData.values,
        20,
      );
      this.pieChartData.datasets[0].data = chartData.values;
      this.pieChartData.labels = chartData.keys;
      this.tableDataLabels = sortedData.keys;
    } else {
      this.pieChartData.datasets[0].data = sortedData.values;
      this.pieChartData.labels = sortedData.keys;
      this.tableDataLabels = sortedData.keys;
    }
    // 変更検知を行うために再代入している
    this.pieChartData = { ...this.pieChartData };
  }

  checkData(): void {
    if (
      Object.entries(this.aggData).filter((value) => value == undefined)
        .length > 0
    ) {
      this.irregularData = true;
    } else {
      this.irregularData = false;
    }
  }

  sortByValue(data: Record<string, number>): {
    keys: string[];
    values: number[];
  } {
    const sortedMap = new Map(
      Object.entries(data).sort(function (a, b) {
        // ソート処理
        const genreA = a[1];
        const genreB = b[1];

        let comparison = 0;
        if (genreA > genreB) {
          comparison = 1;
        } else if (genreA < genreB) {
          comparison = -1;
        }
        return comparison * -1;
      }),
    );

    const sortedKey: string[] = [];
    const sortedValue: number[] = [];
    sortedMap.forEach((val, key) => {
      sortedKey.push(key == '' ? this.unknown : key);
      sortedValue.push(val);
    });

    return {
      keys: sortedKey,
      values: sortedValue,
    };
  }
}

import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LandmarkDisp } from '../constants';

const DEFAULT_HEATMAP_HEIGHT = '512px';
const IN_MODAL_HEATMAP_HEIGHT = 'calc(100vh - 112px)';
@Component({
  selector: 'app-heatmap-view',
  templateUrl: './heatmap-view.component.html',
  styleUrls: ['./heatmap-view.component.scss'],
})
export class HeatmapViewComponent implements OnInit, OnDestroy {
  private readonly translate = inject(TranslateService);

  @Input({ required: true }) mapID!: string;
  @Input({ required: true }) enableHeatmap!: boolean | undefined;
  @Input({ required: true }) enableIllustmap!: boolean | undefined;
  @Input({ required: true }) heatmapRadius!: number;
  @Input({ required: true }) landmarkDisp!: LandmarkDisp;
  @Input({ required: true }) isDataFetchProgress!: boolean;
  @Input({ required: true }) hasHeatMapData!: boolean;
  @Input({ required: true }) isHeatMapModalOpen!: boolean;
  @Output() updateHeatmapRadius = new EventEmitter();
  @Output() updateLandmarkDisp = new EventEmitter();
  @Output() createStrolyMap = new EventEmitter();
  @Output() createStandardMap = new EventEmitter();
  @Output() updateIsHeatMapModalOpen = new EventEmitter();
  private readonly onDestroy$ = new EventEmitter();

  landmarkDispItems = [
    { id: 1, name: LandmarkDisp.Full, disp: '' },
    { id: 2, name: LandmarkDisp.Pin, disp: '' },
    { id: 3, name: LandmarkDisp.None, disp: '' },
  ];

  // 翻訳済みテキスト
  behavioralTitle = '';
  heatmapCaption = '';
  landmarkSetting = '';
  heatmapRadiusLabel = '';
  dataNotFound = '';

  ngOnInit(): void {
    this.initTranslation();
  }
  ngOnDestroy(): void {
    this.onDestroy$.emit();
  }

  get strolyMapHeight() {
    return this.enableIllustmap
      ? this.isHeatMapModalOpen
        ? IN_MODAL_HEATMAP_HEIGHT
        : DEFAULT_HEATMAP_HEIGHT
      : '0';
  }

  get standardMapHeight() {
    return !this.enableIllustmap
      ? this.isHeatMapModalOpen
        ? IN_MODAL_HEATMAP_HEIGHT
        : DEFAULT_HEATMAP_HEIGHT
      : 0;
  }

  private initTranslation(): void {
    this.translate
      .get([
        'behavioral.PinAndName',
        'behavioral.PinOnly',
        'behavioral.Hidden',
        'behavioral.Title',
        'behavioral.HeatMapCaption',
        'behavioral.LandmarkSetting',
        'behavioral.HeatMapRadius',
        'dashboard.DataNotFound',
      ])
      .subscribe((translations) => {
        this.landmarkDispItems[0].disp = translations['behavioral.PinAndName'];
        this.landmarkDispItems[1].disp = translations['behavioral.PinOnly'];
        this.landmarkDispItems[2].disp = translations['behavioral.Hidden'];

        this.behavioralTitle = translations['behavioral.Title'];
        this.heatmapCaption = translations['behavioral.HeatMapCaption'];
        this.landmarkSetting = translations['behavioral.LandmarkSetting'];
        this.heatmapRadiusLabel = translations['behavioral.HeatMapRadius'];
        this.dataNotFound = translations['dashboard.DataNotFound'];
      });
  }

  handleChangeHeatmapRadius(radius: number) {
    this.updateHeatmapRadius.emit(radius);
  }

  handleChangeLandmarkDisp(target: EventTarget | null) {
    if (!target) {
      return;
    }

    console.log((target as HTMLSelectElement).value);

    this.updateLandmarkDisp.emit(
      (target as HTMLSelectElement).value as LandmarkDisp,
    );
  }

  handleCreateStrolyMap(element: HTMLElement) {
    this.createStrolyMap.emit(element);
  }

  handleCreateStandardMap(element: HTMLElement) {
    this.createStandardMap.emit(element);
  }

  openHeatMapModal(): void {
    this.updateIsHeatMapModalOpen.emit(true);
  }

  closeHeatMapModal(): void {
    this.updateIsHeatMapModalOpen.emit(false);
  }
}
